import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { FilterData } from "@/core/types/gws-agent/dashboard/FilterData";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { formatInternationalDate } from "@/core/helpers/date-format";
const authStore = useAuthenticationStore();

export const useDashboardBlkStore = defineStore({
  id: "dashboardBlkStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        date: [new Date(), new Date()],
      } as FilterData,
      stateDisplayData: {
        TOTAL: 0,
        WAITING: 0,
        TRAINING: 0,
        COMPLETED: 0,
        PENDING: 0,
        REJECTED: 0,
      },
      stateBlkDetail: {
        name: "",
        address: "",
        siup: "",
        npwp: null,
        license_start_date: null,
        license_end_date: null,
      },
      stateBlkPhoto: {
        front: "",
      }
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    displayDataBlk(state) {
      return state.stateBlkDetail;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      const [startDate, endDate] = this.stateFilterData.date;
      const params = {
        start_date: formatInternationalDate(startDate.toString()),
        end_date: formatInternationalDate(endDate.toString()),
        organization_type: 'BLK',
        id: authStore?.user?.org_id || "",
      };

      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `dashboards/monitoring_jobseekers?${new URLSearchParams(params).toString()}`
        );

        const dashboardData = response.data.data.monitoring_jobseekers;

        this.stateDisplayData = dashboardData;
      } catch (error) {
        console.log(error);
      }

      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `blk/${authStore?.user?.org_id}`
        );

        const blkDetail = response.data.data;

        this.stateBlkDetail = blkDetail;
        this.stateBlkPhoto = blkDetail.blk_photo_file;
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
  },
});
